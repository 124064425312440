<template>
  <div style="height: auto;margin-bottom: 20px;width: 1200px;margin: 0 auto;padding-bottom: 20px">
    <div
        style="
              display: flex;
              justify-content: center;
              height: 70px;
              line-height: 70px;
              background-color: rgb(54, 194, 207);
              border: 1px solid rgb(54, 194, 207);
              color: white;
              font-size: 20px;
            "
    >
            <span>{{ reportList.enterpriseName }}</span
            >&nbsp;&nbsp;
      <span style="margin-left: 20px"
      >国家高新技术企业测评报告
              <span>({{ reportList.createTime }})</span></span
      >
    </div>
    <div style="height:auto; border: 1px solid rgb(218, 226, 229)">
      <div style="height: 370px; padding-top: 70px" class="div_center">
        <Row :gutter="16">
          <Col span="12">
            <div style="height: 220px">
              <div style="height: 44px; line-height: 44px">
                      <span
                          style="
                          width: 126px;
                          display: inline-block;
                          text-align: right;
                          font-size: 14px;
                          padding-right: 10px;
                        "
                      >企业基本情况得分</span
                      >
                <i-progress :percent="reportList.baseScore">
                  <Icon type="checkmark-circled"></Icon>
                  <span style="color: rgb(54, 194, 207)"
                  >{{ reportList.baseScore }}分</span
                  >
                </i-progress>
              </div>
              <div style="height: 44px; line-height: 44px">
                      <span
                          style="
                          width: 126px;
                          display: inline-block;
                          text-align: right;
                          font-size: 14px;
                          padding-right: 10px;
                        "
                      >企业加分项得分</span
                      >
                <i-progress :percent="reportList.additionalScore">
                  <Icon type="checkmark-circled"></Icon>
                  <span style="color: rgb(54, 194, 207)"
                  >{{ reportList.additionalScore }}分</span
                  >
                </i-progress>
              </div>
              <div style="height: 44px; line-height: 44px">
                      <span
                          style="
                          width: 126px;
                          display: inline-block;
                          text-align: right;
                          font-size: 14px;
                              padding-right: 10px;
                        "
                      >企业财务状况</span
                      >
                <i-progress :percent="reportList.financeScore">
                  <Icon type="checkmark-circled"></Icon>
                  <span style="color: rgb(54, 194, 207)"
                  >{{ reportList.financeScore }}分</span
                  >
                </i-progress>
              </div>
              <div style="height: 44px; line-height: 44px">
                      <span
                          style="
                          width: 126px;
                          display: inline-block;
                          text-align: right;
                          font-size: 14px;
                              padding-right: 10px;
                        "
                      >企业知识产权状况</span
                      >
                <i-progress :percent="reportList.totalScore">
                  <Icon type="checkmark-circled"></Icon>
                  <span style="color: rgb(54, 194, 207)"
                  >{{ reportList.totalScore }}分</span
                  >
                </i-progress>
              </div>
              <div style="height: 44px; line-height: 44px">
                      <span
                          style="
                          width: 126px;
                          display: inline-block;
                          text-align: right;
                          font-size: 14px;
                              padding-right: 10px;
                        "
                      >企业研发管理状况</span
                      >
                <i-progress :percent="reportList.manageScore">
                  <Icon type="checkmark-circled"></Icon>
                  <span style="color: rgb(54, 194, 207)"
                  >{{ reportList.manageScore }}分</span
                  >
                </i-progress>
              </div>
            </div>
          </Col>
          <Col span="12">
            <div
                style="
                      font-size: 14px;
                      height: 220px;
                      border: 1px solid rgb(205, 240, 243);
                      border-right: none;
                      border-bottom: none;
                    "
            >
              <Row>
                <Col span="18">
                  <div class="table-box">企业测评总得分</div>
                </Col>
                <Col span="6">
                  <div class="table-box-double">
                    {{ reportList.totalScore }}分
                  </div>
                </Col>
                <Col span="18">
                  <div class="table-box" style="display: flex;justify-content: space-between;align-items: center">
                    <span>国家高新技术企业认证得分要求</span>
                    <div
                        v-if="data3.countryEval == 'N'"
                        class="arrow_ld"
                        style="background-color: orange"
                    >
                      <Icon type="md-close"/>
                    </div>
                    <div v-else class="arrow_ld">
                      <Icon type="md-checkmark"/>
                    </div>
                  </div>
                </Col>
                <Col span="6">
                  <div class="table-box-double">{{ data3.countryLevel }}</div>
                </Col>
                <Col span="18">
                  <div class="table-box" style="display: flex;justify-content: space-between;align-items: center">
                    <span>{{ reportList.provinceName }}高新技术企业入库标准得分要求</span>
                    <div
                        v-if="data3.provinceEval == 'N'"
                        class="arrow_ld"
                        style="background-color: orange"
                    >
                      <Icon type="md-close"/>
                    </div>
                    <div v-else class="arrow_ld">
                      <Icon type="md-checkmark"/>
                    </div>
                  </div>
                </Col>
                <Col span="6">
                  <div class="table-box-double">{{ data3.provinceLevel }}</div>
                </Col>
                <Col span="18">
                  <div class="table-box" style="display: flex;justify-content: space-between;align-items: center">
                    <span>{{ reportList.provinceName }}{{ reportList.cityName }}高新技术企业入库标准得分要求</span>
                    <div
                        v-if="data3.cityEval == 'N'"
                        class="arrow_ld"
                        style="background-color: orange"
                    >
                      <Icon type="md-close"/>
                    </div>
                    <div v-else class="arrow_ld">
                      <Icon type="md-checkmark"/>
                    </div>
                  </div>
                </Col>
                <Col span="6">
                  <div class="table-box-double">{{ data3.cityLevel }}</div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </div>
      <div style="height: 400px; padding-top: 50px" class="div_center">
        <div
            style="
                  font-size: 14px;
                  height: 300px;
                  border: 1px solid rgb(205, 240, 243);
                  border-right: none;
                  border-bottom: none;
                "
        >
          <Row>
            <Col span="6">
              <div
                  class="table-box"
                  style="
                        height: 50px;
                        text-align: center;
                        background-color: rgb(54, 194, 207);
                        color: white;

                      "
                  title="国家高新技术企业认证基本条件达成情况"
              >
                国家高新技术企业认证基本条件达成情况
              </div>
            </Col>
            <Col span="6">
              <div class="table-box-double" style="height: 50px">
                国家高新技术企业认证标准
              </div>
            </Col>
            <Col span="6">
              <div class="table-box-double" style="height: 50px">
                {{ reportList.provinceName }}国高企入库标准
              </div>
            </Col>
            <Col span="6">
              <div class="table-box-double" style="height: 50px">
                {{ reportList.cityName }}国高企入库标准
              </div>
            </Col>
          </Row>
          <Row v-for="(item,index) in this.data2" :key="index">
            <Col span="6">
              <div
                  class="table-box"
                  style="height: 50px; text-align: center"
                  :title="item.itemCondition"
              >
                {{ item.itemCondition }}
              </div>
            </Col>
            <Col span="3">
              <div class="table-box-double" style="height: 50px" :title="item.countryLevel">
                {{ item.countryLevel }}
              </div>
            </Col>
            <Col span="3">
              <div class="table-box-double" style="height: 50px">
                <div
                    v-if="item.countryEval == 'N'"
                    class="arrow_ld"
                    style="background-color: orange"
                >
                  <Icon type="md-close"/>
                </div>
                <div v-else class="arrow_ld">
                  <Icon type="md-checkmark"/>
                </div>
              </div>
            </Col>
            <Col span="3">
              <div class="table-box-double" style="height: 50px" :title="item.provinceLevel">
                {{ item.provinceLevel }}
              </div>
            </Col>
            <Col span="3">
              <div class="table-box-double" style="height: 50px">
                <div
                    v-if="item.provinceEval == 'N'"
                    class="arrow_ld"
                    style="background-color: orange"
                >
                  <Icon type="md-close"/>
                </div>
                <div v-else class="arrow_ld">
                  <Icon type="md-checkmark"/>
                </div>
              </div>
            </Col>
            <Col span="3">
              <div class="table-box-double" style="height: 50px"> {{ item.cityLevel }}</div>
            </Col>
            <Col span="3">
              <div class="table-box-double" style="height: 50px">
                <div
                    v-if="item.cityEval == 'N'"
                    class="arrow_ld"
                    style="background-color: orange"
                >
                  <Icon type="md-close"/>
                </div>
                <div v-else class="arrow_ld">
                  <Icon type="md-checkmark"/>
                </div>
              </div>
            </Col>
          </Row>
          <!--                <Row>-->
          <!--                  <Col span="6">-->
          <!--                    <div-->
          <!--                        class="table-box"-->
          <!--                        style="height: 50px; text-align: center"-->
          <!--                        title="企业主要产品（服务）符合国家高新技术领域范围"-->
          <!--                    >-->
          <!--                      企业主要产品（服务）符合国家高新技术领域范围-->
          <!--                    </div>-->
          <!--                  </Col>-->
          <!--                  <Col span="3">-->
          <!--                    <div class="table-box-double" style="height: 50px">-->
          <!--                      新材料-->
          <!--                    </div>-->
          <!--                  </Col>-->
          <!--                  <Col span="3">-->
          <!--                    <div class="table-box-double" style="height: 50px">-->
          <!--                      <div class="arrow_ld" style="background-color: orange">-->
          <!--                        <Icon type="md-close"/>-->
          <!--                      </div>-->
          <!--                    </div>-->
          <!--                  </Col>-->
          <!--                  <Col span="3">-->
          <!--                    <div class="table-box-double" style="height: 50px">-->
          <!--                      新材料-->
          <!--                    </div>-->
          <!--                  </Col>-->
          <!--                  <Col span="3">-->
          <!--                    <div class="table-box-double" style="height: 50px">-->
          <!--                      <div class="arrow_ld" style="background-color: orange">-->
          <!--                        <Icon type="md-close"/>-->
          <!--                      </div>-->
          <!--                    </div>-->
          <!--                  </Col>-->
          <!--                  <Col span="3">-->
          <!--                    <div class="table-box-double" style="height: 50px">无</div>-->
          <!--                  </Col>-->
          <!--                  <Col span="3">-->
          <!--                    <div class="table-box-double" style="height: 50px">-->
          <!--                      <div-->
          <!--                          v-if="1 == 2"-->
          <!--                          class="arrow_ld"-->
          <!--                          style="background-color: orange"-->
          <!--                      >-->
          <!--                        <Icon type="md-close"/>-->
          <!--                      </div>-->
          <!--                      <div v-else class="arrow_ld">-->
          <!--                        <Icon type="md-checkmark"/>-->
          <!--                      </div>-->
          <!--                    </div>-->
          <!--                  </Col>-->
          <!--                </Row>-->
          <!--                <Row>-->
          <!--                  <Col span="6">-->
          <!--                    <div-->
          <!--                        class="table-box"-->
          <!--                        style="height: 50px; text-align: center"-->
          <!--                    >-->
          <!--                      科技人员占企业职工总数比例-->
          <!--                    </div>-->
          <!--                  </Col>-->
          <!--                  <Col span="3">-->
          <!--                    <div class="table-box-double" style="height: 50px">-->
          <!--                      >10%-->
          <!--                    </div>-->
          <!--                  </Col>-->
          <!--                  <Col span="3">-->
          <!--                    <div class="table-box-double" style="height: 50px">-->
          <!--                      <div class="arrow_ld" style="background-color: orange">-->
          <!--                        <Icon type="md-close"/>-->
          <!--                      </div>-->
          <!--                    </div>-->
          <!--                  </Col>-->
          <!--                  <Col span="3">-->
          <!--                    <div class="table-box-double" style="height: 50px">-->
          <!--                      >12%-->
          <!--                    </div>-->
          <!--                  </Col>-->
          <!--                  <Col span="3">-->
          <!--                    <div class="table-box-double" style="height: 50px">-->
          <!--                      <div class="arrow_ld" style="background-color: orange">-->
          <!--                        <Icon type="md-close"/>-->
          <!--                      </div>-->
          <!--                    </div>-->
          <!--                  </Col>-->
          <!--                  <Col span="3">-->
          <!--                    <div class="table-box-double" style="height: 50px">无</div>-->
          <!--                  </Col>-->
          <!--                  <Col span="3">-->
          <!--                    <div class="table-box-double" style="height: 50px">-->
          <!--                      <div-->
          <!--                          v-if="1 == 2"-->
          <!--                          class="arrow_ld"-->
          <!--                          style="background-color: orange"-->
          <!--                      >-->
          <!--                        <Icon type="md-close"/>-->
          <!--                      </div>-->
          <!--                      <div v-else class="arrow_ld">-->
          <!--                        <Icon type="md-checkmark"/>-->
          <!--                      </div>-->
          <!--                    </div>-->
          <!--                  </Col>-->
          <!--                </Row>-->
          <!--                <Row>-->
          <!--                  <Col span="6">-->
          <!--                    <div-->
          <!--                        class="table-box"-->
          <!--                        style="height: 50px; text-align: center"-->
          <!--                        title="一年内重大安全、重大质量事故或严重环境违法行为"-->
          <!--                    >-->
          <!--                      一年内重大安全、重大质量事故或严重环境违法行为-->
          <!--                    </div>-->
          <!--                  </Col>-->
          <!--                  <Col span="3">-->
          <!--                    <div class="table-box-double" style="height: 50px">-->
          <!--                      未发生-->
          <!--                    </div>-->
          <!--                  </Col>-->
          <!--                  <Col span="3">-->
          <!--                    <div class="table-box-double" style="height: 50px">-->
          <!--                      <div class="arrow_ld" style="background-color: orange">-->
          <!--                        <Icon type="md-close"/>-->
          <!--                      </div>-->
          <!--                    </div>-->
          <!--                  </Col>-->
          <!--                  <Col span="3">-->
          <!--                    <div class="table-box-double" style="height: 50px">-->
          <!--                      未发生-->
          <!--                    </div>-->
          <!--                  </Col>-->
          <!--                  <Col span="3">-->
          <!--                    <div class="table-box-double" style="height: 50px">-->
          <!--                      <div class="arrow_ld" style="background-color: orange">-->
          <!--                        <Icon type="md-close"/>-->
          <!--                      </div>-->
          <!--                    </div>-->
          <!--                  </Col>-->
          <!--                  <Col span="3">-->
          <!--                    <div class="table-box-double" style="height: 50px">无</div>-->
          <!--                  </Col>-->
          <!--                  <Col span="3">-->
          <!--                    <div class="table-box-double" style="height: 50px">-->
          <!--                      <div-->
          <!--                          v-if="1 == 2"-->
          <!--                          class="arrow_ld"-->
          <!--                          style="background-color: orange"-->
          <!--                      >-->
          <!--                        <Icon type="md-close"/>-->
          <!--                      </div>-->
          <!--                      <div v-else class="arrow_ld">-->
          <!--                        <Icon type="md-checkmark"/>-->
          <!--                      </div>-->
          <!--                    </div>-->
          <!--                  </Col>-->
          <!--                </Row>-->
          <!--                <Row>-->
          <!--                  <Col span="6">-->
          <!--                    <div-->
          <!--                        class="table-box"-->
          <!--                        style="height: 50px; text-align: center"-->
          <!--                    >-->
          <!--                      企业知识产权要求-->
          <!--                    </div>-->
          <!--                  </Col>-->
          <!--                  <Col span="3">-->
          <!--                    <div-->
          <!--                        class="table-box-double"-->
          <!--                        style="height: 50px"-->
          <!--                        title="I类1项以上、II类5项以上"-->
          <!--                    >-->
          <!--                      I类1项以上、II类5项以上-->
          <!--                    </div>-->
          <!--                  </Col>-->
          <!--                  <Col span="3">-->
          <!--                    <div class="table-box-double" style="height: 50px">-->
          <!--                      <div class="arrow_ld" style="background-color: orange">-->
          <!--                        <Icon type="md-close"/>-->
          <!--                      </div>-->
          <!--                    </div>-->
          <!--                  </Col>-->
          <!--                  <Col span="3">-->
          <!--                    <div-->
          <!--                        class="table-box-double"-->
          <!--                        style="height: 50px"-->
          <!--                        title="I类1项以上、II类5项以上"-->
          <!--                    >-->
          <!--                      I类1项以上、II类5项以上-->
          <!--                    </div>-->
          <!--                  </Col>-->
          <!--                  <Col span="3">-->
          <!--                    <div class="table-box-double" style="height: 50px">-->
          <!--                      <div class="arrow_ld" style="background-color: orange">-->
          <!--                        <Icon type="md-close"/>-->
          <!--                      </div>-->
          <!--                    </div>-->
          <!--                  </Col>-->
          <!--                  <Col span="3">-->
          <!--                    <div class="table-box-double" style="height: 50px">无</div>-->
          <!--                  </Col>-->
          <!--                  <Col span="3">-->
          <!--                    <div class="table-box-double" style="height: 50px">-->
          <!--                      <div-->
          <!--                          v-if="1 == 2"-->
          <!--                          class="arrow_ld"-->
          <!--                          style="background-color: orange"-->
          <!--                      >-->
          <!--                        <Icon type="md-close"/>-->
          <!--                      </div>-->
          <!--                      <div v-else class="arrow_ld">-->
          <!--                        <Icon type="md-checkmark"/>-->
          <!--                      </div>-->
          <!--                    </div>-->
          <!--                  </Col>-->
          <!--                </Row>-->
        </div>
      </div>
      <div
          style="height: auto; border-bottom: none; padding-top: 50px;padding-bottom: 50px"
          class="div_center"
      >
        <div
            style="
                  font-size: 14px;
                  height: auto;
                  border: 1px solid rgb(205, 240, 243);
                  border-right: none;
                  border-bottom: none;
                "
        >
          <!--<Row v-for="(item,index) in last_table" :key="index">-->
          <!--<Col span="12">-->
          <!--<div class="table-box" style="height: 50px;text-align: center;" :title="item[0]" :class="{'div_first_td':index==0}">-->
          <!--{{item[0]}}-->
          <!--</div>-->
          <!--</Col>-->
          <!--<Col span="4"> <div class="table-box-double" style="height: 50px"> {{item[1]}}分</div></Col>-->
          <!--<Col span="4"> <div class="table-box-double" style="height: 50px"> {{item[2]}}分</div></Col>-->
          <!--<Col span="4">-->
          <!--<div class="table-box-double" style="height: 50px">-->
          <!--{{item[3]}}分-->
          <!--</div>-->
          <!--</Col>-->

          <!--</Row>-->
          <Table
              border
              :columns="columns1"
              :data="data1"
              :disabled-hover="true"
              id="origin"
          ></Table>
        </div>
      </div>
<!--      <div-->
<!--          style="height: 180px; border-top: none"-->
<!--          class="next_style common_liucheng;"-->
<!--      >-->
<!--        <div style="height: 40px; width: 120px;display: none" class="button_style">-->
<!--          下载报告-->
<!--        </div>-->
<!--        <div-->
<!--            @click="reTest()"-->
<!--            style="-->
<!--                  height: 40px;-->
<!--                  width: 120px;-->
<!--                  background-color: #fc7c40;-->
<!--                  margin-left: 20px;-->
<!--                "-->
<!--            class="button_style"-->
<!--        >-->
<!--          重新测评-->
<!--        </div>-->
<!--      </div>-->
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import {errorToast} from "../plugins/tools/util";
import { hostname } from "../config"
export default {
name: "ExamReport",
  data() {
    return {
      oldEvalId: "",
      cityData: {},
      provinceData: {"": ''},
      thisYear: "",
      evalId: "",
      transfer: true,
      secondFlag: false,
      thirdFlag: false,
      // 第一步from
      baseForm: {
        awards: "",
        contactTel: "",
        contactUser: "",
        creditCode: "",
        detailAddress: "",
        mptCode: "",
        punishment: "",
        registerTime: "",
        researcherNumber: "",
        staffTotal: "",
      },

      // 第二步form
      ipList1: [
        {
          ipClass: 1,
          ipItemCode: "FMZL",
          selfDevNumber: 0,
          traGiftMergerNumber: 0,
        },
        {
          ipClass: 1,
          ipItemCode: "ZWXPZ",
          selfDevNumber: 0,
          traGiftMergerNumber: 0,
        },
        {
          ipClass: 1,
          ipItemCode: "NZWPZ",
          selfDevNumber: 0,
          traGiftMergerNumber: 0,
        },
        {
          ipClass: 1,
          ipItemCode: "GJXY",
          selfDevNumber: 0,
          traGiftMergerNumber: 0,
        },
        {
          ipClass: 1,
          ipItemCode: "BHPZ",
          selfDevNumber: 0,
          traGiftMergerNumber: 0,
        },
        {
          ipClass: 1,
          ipItemCode: "SJZYQ",
          selfDevNumber: 0,
          traGiftMergerNumber: 0,
        },
      ],
      ipList2: [
        {
          ipClass: 2,
          ipItemCode: "SYXXZL",
          selfDevNumber: 0,
          traGiftMergerNumber: 0,
        },
        {
          ipClass: 2,
          ipItemCode: "RJZZQ",
          selfDevNumber: 0,
          traGiftMergerNumber: 0,
        },
      ],
      data3: [],
      reportList: [], //报告内容
      //第三步form
      financeFrom: {
        evalId: "",
        last1stYearNetAssetsTotal: 0,
        last1stYearRdInvestmentTotal: 0,
        last1stYearSaleRevenue: 0,
        last2ndYearNetAssetsTotal: 0,
        last2ndYearRdInvestmentTotal: 0,
        last2ndYearSaleRevenue: 0,
        last3rdYearNetAssetsTotal: 0,
        last3rdYearRdInvestmentTotal: 0,
        last3rdYearSaleRevenue: 0,
        lastYearHighTechRate: "A",
        threeYearDomesticRdRate: "B",
        threeYearRdRate: "A",
      },

      // 四form
      fourthForm: {
        evalId: "22",
        excitationInnovate: [],
        rdCooperation: [],
        rdManage: [],
        trainingIncentive: [],
      },
      technologyList: [],
      nowIndex: 1,
      has: "有",
      nohas: false,
      hjqk: "",
      jsly: "",
      sciencePeople: [],
      current: 0,
      tab_height: 0,
      tab_list: ["国家高新技术企业体检"],
      isChoose: 0,
      contentIndex: 0,
      data2: [],
      keyword: "",
      columns1: [
        {
          title: "国家高新技术企业认证具体得分情况",
          key: "itemCondition",
          width: "600px",
          className: "demo-table-info-column",
        },
        {
          title: "国家高新技术企业认证标准",
          key: "countryEval",
        },
        {
          title: "江苏省国企入库标准",
          key: "provinceEval",
        },
        {
          title: "南京市国高企入库标准",
          key: "cityEval",
        },
      ],
      IP_ITEM_List: [],
      BASE_INF_List: [],
      data1: [],
    };
  },
  mounted() {
    let id = this.$route.query.evalId
    axios.get(`http://service.${hostname}/portal/home/report?evalId=${id}`).then((json) => {


     if(json.data.code == 0){
       this.reportList = json.data.data;

       this.columns1[2].title =  this.reportList.provinceName+'国企入库标准';
       this.columns1[3].title =  this.columns1[3].title = this.reportList.cityName +'国企入库标准'
       let dateee2 = new Date(this.reportList.createTime).toJSON();
       this.reportList.createTime = new Date(
           +new Date(dateee2) + 8 * 3600 * 1000
       )
           .toISOString()
           .replace(/T/g, " ")
           .replace(/\.[\d]{3}Z/, "")
           .split(" ")[0];
       this.data1 = this.reportList.itemList;
       this.data2 = this.reportList.baseList;
       this.data3 = this.reportList.headList;
       if (!this.data3['provinceLevel']) {
         this.data3['provinceLevel'] = '无'
       }
       if (!this.data3['countryLevel']) {
         this.data3['countryLevel'] = '无'
       }
       if (!this.data3['cityLevel']) {
         this.data3['cityLevel'] = '无'
       }

       for (let i = 0; i < this.data2.length; i++) {
         if (!this.data2[i]['provinceLevel']) {
           this.data2[i]['provinceLevel'] = '无'
         }
         if (!this.data2[i]['countryLevel']) {
           this.data2[i]['countryLevel'] = '无'
         }
         if (!this.data2[i]['cityLevel']) {
           this.data2[i]['cityLevel'] = '无'
         }
       }
       this.data1.forEach((item, index) => {
         if (!item['countryEval']) {
           item['countryEval'] = '0分'
         } else {
           item['countryEval'] += '分'
         }
         if (!item['provinceEval']) {
           item['provinceEval'] = '0分'
         } else {
           item['provinceEval'] += '分'
         }
         if (!item['cityEval']) {
           item['cityEval'] = '0分'
         } else {
           item['cityEval'] += '分'
         }

       })
       this.nowIndex = 0;
       this.changeHeight();
       this.$nextTick(() => {
         for (let i = 0; i < this.ipList2.length; i++) {
           this.ipList2[i].selfDevNumber = 0;
           this.ipList2[i].traGiftMergerNumber = 0
         }
         for (let i = 0; i < this.ipList1.length; i++) {
           this.ipList1[i].selfDevNumber = 0;
           this.ipList1[i].traGiftMergerNumber = 0
         }


         for(let item in this.fourthForm){
           if(item == 'evalId'){
             this.fourthForm[item] = "";
           }else{
             this.fourthForm[item] = [];
           }
         }
       })
     }else{
       errorToast(json.message)
     }
    })


  }
}
</script>

<style scoped lang="scss">
/deep/ .table_div2 .ivu-input {
  height: 50px !important;
  text-align: center !important;
  padding: 0 0;
  border-color: rgba(205, 240, 243, 1);
}

/deep/ .ivu-select {
  width: 50% !important;
}


.table_div {
  height: 50px;
  text-align: center;
  line-height: 50px;
  width: 100%;
  border-right: 1px solid rgba(205, 240, 243, 1);
  border-bottom: 1px solid rgba(205, 240, 243, 1);
}

/deep/ .ivu-select-selection {
  border-radius: 0;
}

.table_div2 {
  height: 50px;
  text-align: center;
  line-height: 50px;
  width: 100%;
}

.table_div_two {
  border-right: none !important;
}

/deep/ .ivu-steps-item.ivu-steps-status-process .ivu-steps-head-inner {
  background: rgb(54, 194, 207);
  border: rgb(54, 194, 207);
}

/deep/ .ivu-steps-head-inner span {
}

/deep/ .ivu-steps .ivu-steps-title {
  display: block;
  margin-top: 25px;
}

/deep/ .ivu-table-cell {
  text-align: center !important;

  & > span {
    text-align: center !important;
  }
}

/deep/ .ivu-table th {
  height: 50px !important;
  text-align: center;
}

.ivu-table-row {
  height: 50px !important;
}

/deep/ .ivu-table td {
  height: 50px !important;
}

/deep/ .ivu-radio-inner:after {
  background-color: rgb(54, 194, 207);
}

.arrow_ld {
  color: white !important;
  display: inline-block;
  height: 14px;
  width: 14px;
  border-radius: 50%;
  background: rgb(54, 194, 207);
  line-height: 14px;
  text-align: center;
}

.div_first_td {
  background-color: rgb(54, 194, 207) !important;
  color: white;
}

.table-box {
  width: 100%;
  height: 55px;
  background-color: #f3fcfc;
  line-height: 55px;
  text-align: left;
  border-bottom: 1px solid rgba(205, 240, 243, 1);
  border-right: 1px solid rgb(205, 240, 243);
  padding: 0 10px;
  overflow: hidden;
  font-size: 14px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.table-box2 {
  width: 100%;
  height: 55px;
  line-height: 55px;
  text-align: left;
  border-bottom: 1px solid rgba(205, 240, 243, 1);
  border-right: 1px solid rgb(205, 240, 243);
  font-size: 14px;
}

.table-box-double {
  width: 100%;
  height: 55px;
  line-height: 55px;
  text-align: center;
  border-bottom: 1px solid rgb(205, 240, 243);
  border-right: 1px solid rgb(205, 240, 243);
  font-size: 14px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 0 10px;
}

.ivu-progress {
  display: inline-block !important;
  width: 65% !important;
}

.div_center {
  width: 95%;
  margin-left: 50%;
  transform: translateX(-50%);
  border-bottom: 2px dotted rgb(218, 226, 229);
}

.neirong {
  margin-left: 130px;

  .download_span {
    color: blue;
    display: inline-block;
    margin-right: 30px;
  }

  p {
    display: inline-block;
    margin-bottom: 20px;
    font-size: 14px;
    color: black;
  }
}

.bottom_style {
  position: absolute;
  transform: translateX(-50%);
  left: 50%;
  bottom: -110px;
}

.common_liucheng:nth-child(1) {
  border: none;
}

.common_liucheng {
  border-top: 2px dotted #f5f5f5;
  width: 95%;
  position: relative;
  left: 50%;
  transform: translateX(-50%);

  & > span {
    display: block;
    padding-left: 5px;
    padding-top: 38px;
    font-size: 18px;
    font-family: Microsoft YaHei;
    color: rgb(51, 51, 51);
    margin-bottom: 60px;
  }
}

.next_style {
  display: flex;
  align-items: center;
  justify-content: center;
}

.right_real_content {
  margin-bottom: 50px;
  width: 95%;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 40px;
  overflow: hidden;
}

.right_header {
  width: 95%;
  padding-top: 20px;
  height: 140px;
  position: relative;
  margin-bottom: 40px !important;
  left: 50%;
  transform: translateX(-50%);
  border-bottom: 2px dotted #f5f5f5;
  overflow: hidden;

  span {
    display: block;
    margin-bottom: 20px;
  }
}

.button_style {
  display: inline-block;
  border-radius: 2px;
  background-color: rgb(54, 194, 207);
  width: 60px;
  height: 40px;
  z-index: 51;
  line-height: 40px;
  text-align: center;
  font-size: 14px;
  color: white;
  cursor: pointer;
}

.input_search,
.input_search:focus {
  border-width: 1px;
  border-color: rgb(55, 197, 207);
  border-style: solid;
  border-radius: 2px;
  background-color: rgb(255, 255, 255);
  box-shadow: 6px 0px 15px 0px rgba(127, 127, 127, 0.1);
  width: 300px;
  height: 40px;
  outline: none;
}

.isChoose {
  color: #37c5cf;
}

.right_content {
  width: 90%;
  /*border-width: 1px;*/
  /*border-color: rgb(218, 226, 229);*/
  /*border-style: solid;*/
  background-color: rgb(255, 255, 255);
  min-width: 900px;
  height: auto;
}

.right_content_parent {
  box-sizing: border-box;
  width: 80%;
  min-width: 960px;
  float: left;
  display: flex;
  justify-content: center;
  padding-top: 50px;
  position: relative;
  margin-bottom: 130px;
}

.left_tab_parent {
  float: left;
  min-width: 240px;
  width: 20%;
  background-color: rgb(250, 250, 252);

  .left_tab {
    margin-top: 60px;

    div {
      width: 210px;
      margin: 0 auto;
      height: 48px;
      line-height: 48px;
      font-size: 14px;
      box-sizing: border-box;
      padding-left: 31px;
      margin-bottom: 20px;
      border-width: 1px;
      border-color: rgb(235, 235, 235);
      border-style: solid;
      background-color: rgb(255, 255, 255);
      cursor: pointer;
    }
  }
}

/deep/ .demo-table-info-column {
  background-color: #f3fcfc;
  color: white;
}

/deep/ .ivu-table-tbody .demo-table-info-column {
  color: black !important;
}

.hidden_span {
  width: 180px !important;
  display: inline-block;
  vertical-align: top;
  font-size: 12px;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  overflow: hidden;
  //text-overflow: ellipsis;
  //display: -webkit-box;
  //-webkit-line-clamp: 2;
  //-webkit-box-orient: vertical;
  //padding: 0 10px!important;
  //width: 200px!important;
  //overflow: hidden!important;
  //text-overflow: ellipsis!important;
  //white-space: nowrap!important;
  //display: inline-block;
}

</style>